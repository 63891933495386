<template>
  <div class="d-flex justify-start" style="min-height: 30px">
    <div style="flex-basis: 120px; min-width: 120px">{{ label }}</div>

    <div class="flex-grow-1 d-flex flex-column">
      <slot></slot>
      <v-divider class="mt-auto" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextLabel',
  props: {
    label: {type: String, default: null}
  }
}
</script>
