<template>
  <div>
    <page-loading :loading="initializing" />

    <v-card v-if="!initializing" class="pa-4 mb-3">
      <TextLabel label="ID" class="mb-3">
        {{ model.id }}
      </TextLabel>

      <TextLabel :label="$t('users.user')" class="mb-3">
        {{ model.userInfo.name }}
      </TextLabel>

      <TextLabel :label="$t('workingSites.workingSite')" class="mb-3">
        {{ model.workingSiteInfo.name }}
      </TextLabel>

      <TextLabel :label="$t('alerts.subject')" class="mb-3">
        {{ model.subject }}
      </TextLabel>

      <TextLabel :label="$t('alerts.content')" class="mb-3">
        {{ model.content }}
      </TextLabel>

      <TextLabel :label="$t('alerts.alertType')" class="mb-3">
        {{ $t(`alerts.alertTypeInfo.${model.alertType}`) }}
      </TextLabel>

      <TextLabel :label="$t('alerts.priority')" class="mb-3">
        {{ model.priority }}
      </TextLabel>

      <TextLabel :label="$t('shared.created')" class="mb-3">
        <DateDisplay :value="model.created" />
      </TextLabel>

      <TextLabel :label="$t('alerts.priority')">
        {{ model.priority }}
      </TextLabel>
    </v-card>

    <v-card class="pa-4 mb-3">
      <TextLabel :label="$t('alerts.response')" class="mb-3">
        {{ $t(`alerts.${model.alertType}.${model.option}`) }}
      </TextLabel>

      <TextLabel :label="$t('shared.date')">
        <DateDisplay :value="model.modified" />
      </TextLabel>
    </v-card>

    <v-card class="pa-3">
      <page-footer :loading="progressing" hideEdit :btnText="$t('alerts.check')" icon="mdi-check" @submit="checkAlert" />
    </v-card>
  </div>
</template>

<script>
import EditForm from './EditForm'
import TextLabel from '@/pages/alerts/components/TextLabel'
export default {
  name: 'Edit',
  props: ['id'],
  mixins: [EditForm],
  components: {
    TextLabel
  },
  created() {
    this.getModel()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    getModel() {
      return this.$api.org.alerts.getById(this.id).then((res) => {
        this.model = res
        this.$setBreadcrumbs([
          {text: this.$t('nav.alerts'), to: '/org/alerts/index'},
          {text: this.$t('shared.view'), disabled: true}
        ])
      })
    },
    checkAlert() {
      const model = {checked: true}
      return this.$api.org.alerts
        .update(this.id, model)
        .then(() => {
          this.$showSuccess(this.$t('alerts.alertUpdated'))
        })
        .catch((err) => {
          this.$showError(err)
        })
    }
  }
}
</script>
